<template>
  <div class="pa-3">
    <small class="grey--text text-left d-block ml-1">
      {{ new Date().toDateString() }}
    </small>
    <v-row>
      <v-toolbar class="transparent font-weight-bold mb-1" flat>
        <small class="mr-2">Category: </small>
        <v-chip small class="primary lighten-4 primary--text" style="">
          {{ recordDetails.category.name }}
        </v-chip>
        <small style="position: absolute; bottom: -2px">
          Link:
          {{ shareableLink }}
          <v-text-field
            style="position: absolute; opacity: 0"
            :value="shareableLink"
            :id="recordDetails.video_url"
          ></v-text-field>
          <small id="success" class="error--text"></small>
          <v-icon
            color="primary"
            @click="copyLink(recordDetails.video_url)"
            size="18"
            title="copy link"
          >
            mdi-link-variant
          </v-icon>
        </small>
      </v-toolbar>
      <span
        style="position: absolute; top: 135px"
        class="ml-3 font-weight-bold"
      >
        <small>Title: </small> {{ recordDetails.title }}
        <v-icon
          size="16"
          color="primary"
          title="Edit title"
          @click="openEditDialog('title')"
        >
          mdi-text-box-edit-outline
        </v-icon>
      </span>
      <v-spacer></v-spacer>
      <small @click="goBack" class="pointer mr-4">
        <v-icon>mdi-keyboard-backspace</v-icon>
        Go back
      </small>
    </v-row>
    <v-row class="mt-7">
      <v-col cols="12" md="8">
        <v-card class="white rounded-xl videoContainer" flat>
          <video
            class="rounded-xl"
            width="100%"
            height="auto"
            controls
            :src="recordDetails.video_url"
          ></video>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="white rounded-xl pa-2 text-left" flat>
          <div class="rounded-xl secondary pa-2 px-3 mb-2">
            <p class="my-0 grey--text">Recorded By</p>
            <strong class="py-0">
              <small>{{ recordDetails.user.name }}</small>
            </strong>
            <small class="primary--text">
              ({{ recordDetails.user.email }})
            </small>
          </div>
          <div class="rounded-xl secondary pa-2 px-3 mb-2">
            <p class="my-0 grey--text">Platform</p>
            <strong class="py-0">
              <small>
                {{
                  recordDetails.platform
                    ? recordDetails.platform
                    : "Windows 10.0 / Chrome 97.0 4692.99"
                }}
              </small>
            </strong>
          </div>
          <div class="rounded-xl secondary pa-2 px-3 mb-2">
            <p class="my-0 grey--text">Screen Resolution</p>
            <strong class="py-0">
              <small>1920x1080</small>
            </strong>
          </div>
          <div class="rounded-xl secondary pa-2 px-3 mb-2">
            <p class="my-0 grey--text">Requested</p>
            <strong class="py-0">
              <small>
                {{
                  recordDetails.requested === "yes"
                    ? "Yes"
                    : "Not request, submitted via public form " +
                      recordDetails.created_at
                }}
              </small>
            </strong>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="text-left mt-n6">
        <span>
          <small>Note/Description: </small>
          {{ recordDetails.message }}
          <v-icon
            size="16"
            color="primary"
            title="Edit note/description"
            @click="openEditDialog('note')"
          >
            mdi-text-box-edit-outline
          </v-icon>
        </span>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="400">
      <v-card class="" flat>
        <v-toolbar class="error--text" flat>
          <v-spacer></v-spacer>
          <v-card-title>Warning!!!</v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = !dialog" icon>
            <v-icon size="15" color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="text-left d-inline-block">
          <v-card-text class="py-0">
            You don't have enough subscription minutes to view this recording,
            kindly subscribe or upgrade your plan.
          </v-card-text>
          <v-card-subtitle class="pb-0">
            Plan minutes left:
            <strong>{{ user.minutes_left || 0 }} minutes</strong>
          </v-card-subtitle>
          <v-card-subtitle class="py-0">
            Video duration:
            <strong>
              {{ recordminutes.toFixed(2) }}
              {{ recordminutes >= 2 ? "minutes" : "minute" }}
            </strong>
          </v-card-subtitle>
        </div>
        <v-card-actions>
          <v-btn
            class="capitalize rounded-xl mt-2"
            color="primary"
            to="/account-upgrade"
            block
          >
            Upgrade now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-recording-dialog
      ref="editDialog"
      :type="type"
    ></edit-recording-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditRecordingDialog from "./EditRecordingDialog";
export default {
  components: {
    EditRecordingDialog,
  },
  data() {
    return {
      video: null,
      duration: null,
      dialog: false,
      deducted: false,
      type: null,
    };
  },
  mounted() {
    if (this.user.roles !== "admin") {
      this.video = document.querySelector("video");
      let details = this.recordDetails;
      let minutes_left = this.user.minutes_left;
      this.duration = details.duration / 60;
      let beneficiary = this.user.beneficiaries.find(
        (x) => x.user.minutes_left >= this.duration
      );
      if ((this.video && this.duration) || !this.duration) {
        this.video.onplay = () => {
          if (this.user.id === details.received_by && details.viewed === "no") {
            if (
              (!beneficiary && !minutes_left) ||
              (minutes_left && minutes_left < this.duration && !beneficiary)
            ) {
              this.dialog = true;
              this.video.pause();
            } else if (
              !minutes_left ||
              (minutes_left < this.duration && beneficiary)
            ) {
              if (!this.deducted) {
                this.updateRecordingAsViewed(details);
              }
            } else {
              if (!this.deducted) {
                this.updateRecordingAsViewed(details);
              }
            }
          }
        };
      } else {
        this.video.pause();
      }
    }
  },
  computed: {
    ...mapGetters("recordings", ["recordDetails"]),
    ...mapGetters("auth", ["user"]),
    recordminutes() {
      return this.recordDetails.duration / 60;
    },
    shareableLink() {
      let link = "";
      link = process.env.VUE_APP_URL + "/share/" + this.recordDetails.id;
      return link;
    },
  },
  methods: {
    openEditDialog(type) {
      // this.type = recordDetails;
      this.type = type;
      this.$refs.editDialog.openDialog(this.recordDetails);
    },
    goBack() {
      this.deducted = false;
      this.$router.go(-1);
    },
    async updateRecordingAsViewed(details) {
      try {
        const response = await this.$axios.post(
          "/recordings/" + details.id + "/viewed"
        );
        this.$store.commit("auth/USER_DATA", response.data.user, {
          root: true,
        });
        this.deducted = true;
      } catch (e) {
        console.log(e);
      }
    },
    copyLink(id) {
      const link = document.getElementById(id);
      link.select();
      document.execCommand("copy");
      const success = document.getElementById("success");
      success.innerHTML = "copied!";
      console.log(link);
      setTimeout(() => {
        success.innerHTML = "";
      }, 2000);
    },
  },
};
</script>

<style scoped>
.videoContainer {
  height: 93%;
  width: 100%;
  overflow: hidden;
}

.videoContainer video {
  min-width: 100%;
  min-height: 100%;
}
</style>
